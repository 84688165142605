// TEMP PATCH FIX; TODO: NETLIFY CMS INSTEAD

import React, { useEffect } from 'react'
import styles from './Story.module.css';
import { setup } from './StorySetup';

function Stories() {
  // FIXME: disgusting old JS hack
  useEffect(() => {
    setup();
  })

  return <main id={styles.main}>
    <a href="../stories"><img id={styles.return} alt="return" src="../images/return.png" /></a>   
    <a href="../"><img id={styles.dog} alt="dog" src="../images/dog.png" /></a>
    <h1 className={styles.title}>The Electricians</h1>
    <section>
        <p>When Arnie arrived at the manor, he saw he was one of many potential onlookers. Cars had started to pile up along the side of the mountain road 10 or 15 minutes earlier, but he’d been too excited at the prospect of the manor to understand the reasons. Here, in the great mess of gravel in front of the manor, forced into landlocking a grey Camry, which already landlocked a Toyota SUV, and with the grim realization that he would soon be landlocked too, he felt like a prize idiot.
        </p><p>“Are all these people here for the same reason as me?”, he asked himself, under his breath but loud enough so that the people around him knew he liked to talk to himself. If they could begin talking to themselves under their breath too, he would greatly appreciate it, and would feel less he was wrong to believe in the manor. It stood alone and still among a field of trim yellow grass. There was something almost French about it. Not in its low expansiveness or great windows, which were as like Versailles as could be, but instead in the tri-colored flags spotting up along the roof. He could almost imagine himself to be firmly outside the imaginary lines guarding the state of New Hampshire.
        </p><p>The people had begun to congregate around the manor, or, as he looked more closely, a thin electrical wire stretching all the way around it in a circle. It stayed about 20 feet away from every wall, and these tourists, braindead Arnie’s, respected it like it was their own mother’s grave. He saw that if he wanted to put himself right next to it, move his toes impossibly close like all the others, he would have to go around to the back like they were at an airport baggage claim, or otherwise push through what now seemed to be a wall three or four people thick. 
        </p><p>Arnie felt small around these people, even the ones shorter than him (especially the women), and now began to urgently sift back through the people rushing towards him so he could find his car. Looking out over the sea of dim dry metal, he realized that not only could he not see his car, but he couldn’t even see the road. The outcroppings of trees circling the manor in photos on the website, well the forest really, were visible only when he stood on his tippy toes. He felt like asking someone to put him on their shoulders, but he knew nobody around him would hear. 
        </p><p>It took him some time but eventually he managed to make it to the back end of the manor. This side had fewer flags, fewer windows, but more doors. Even before he made his way up to the wire he had already counted seven. Now, after carefully maneuvering through these noticeably less aggressive back onlookers, he could move his toes up close to the wire like everybody else. It was an inch or two thick and laid inertly on the grass like a dead snake. Everyone was refusing to step over it. But that had been clear on the website. It didn’t surprise Arnie at all. What shocked him was a rising energy in him, a powerful and virulent energy he hadn’t felt since his fingers grazed cartoonish images of an electrocuted Ben Franklin holding a kite attached to a key. He lifted up his foot, and stepped over the line. He put his other boot over it. He looked around. Nobody had joined him. Nobody was even looking at him.
        </p><p>Up close the manor wasn’t as large as he had imagined from outside the wire. He glanced back every now and then, but nobody had taken the plunge with him. He wondered if he should go around to the front, with all of the glass windows, so that he could at least get some reaction for his bravery from the crowd. A better idea occurred to him, especially as he remembered the French flags along the roof, and he went up some steps to one of the small and simple back doorways, and pulled the doorknob to let himself in.
        </p><p>Inside he felt nothing, even after he closed the door. There was an air to the manor that grew from within natural air, and it glued itself to his hair and his skin. He let it swarm over him. It wasn’t a tingly feeling, it felt sharp and sudden, almost wet, but the longer he stood there the further away the sensation felt from him. He began to hear his boots echo through the place, and he looked down to see the ground moving his feet.
        </p><p>Upstairs, white sheets covered the furniture, and he could see out over the people in the front of the manor. There must have been a thousand by this point. There were so many heads, so many packs of colorful clothes. He wondered if they saw his movement through the layers of hallways and one layer of window that he saw them through. It was no matter, as before he knew it he was on top the whole damn place.
        </p><p>New Hampshire is a small state, historically, but it has always allowed for men to become giants in its mountains. Arnie felt the wind crash against him, and he loved it. The roof was empty, and there wasn’t anything of interest, except for the flags and the wide marble railing that went around the entire roof. Some of it was crushed at intervals, seemingly by a giant jackhammer. He moved towards the one fully intact railing that sat over the main crowd, putting his palms flat down against the marble. He now could not ignore all of the faces turned up to look at him. He smiled, more than he ever had before, beginning to experience the delusionary dreams that once took hold of Austen and Bergman.
        </p><p>A man, dressed like any other man, walked over the wire directly in front of him. Arnie made eye contact with him, perhaps meeting his lifelong business partner, but the man turned around and began to speak through his megaphone, which Arnie realized was connected to speakers that surrounded the entire manor.
        </p><p>“Does everyone see this man?”. His arm was pointed in the direction of Arnie. The crowd murmured in general assent. The man, waited, paused a bit for dramatic effect. Then raised his megaphone up to his mouth again. “Now we’re going to see what happens when you don’t wait for the electricians.” 
        </p>
    </section>
  </main>
}

export default Stories